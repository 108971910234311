<template>
    <div id="references">
        <!-- JUMBOTRON -->
        <div class="knowledge-base-jumbotron">
            <div class="knowledge-base-jumbotron-content lg:p-32 md:p-24 sm:p-16 py-8 rounded-lg mb-base">
                <h1 class="mb-1 text-white">Oluşum Bilgi İşlem</h1>
                <h1 class="mb-1 text-white">En<strong> İyisiyle </strong> Çalıştığınızdan Emin Olmalısınız!</h1>
                <p class="text-white">Marketten Restorana,Zincir Mağazalardan Kobi İşletmelerine,İnşaat'tan Gıda'ya, Otomotiv'den Mobilya'ya, Demir-Çelik'ten Enerji'ye hemen hemen tüm üretim sektörlerinde yeralan şirketlerden, İthalat ve İhracat alanında sektörünün önünde yeralan şirketlerden, Perakende ve Toptan Alım-Satım alanında faaliyet gösteren tüm ticari kuruluşlara, Lojistik, Eğitim,Dershane,Kolej,Sağlık ve Turizm'den Müşavirlik Hizmetlerine kadar tüm hizmet sektöründe her ölçekteki Özel ve Kamu kuruluşlarına yönelik ürünleri ile kullanıcı odaklı özel çözümler sunuyoruz.</p>
								<h1 class="mb-1 text-white">Bazı Referanslarımız</h1>
                <vs-input placeholder="Arama Konusu veya Anahtar Kelime" v-model="knowledgeBaseSearchQuery" icon-pack="feather" icon="icon-search" size="large" class="w-full no-icon-border mt-6" />
            </div>
        </div>

        <!-- KNOWLEDGE BASE CARDS  -->
        <div class="vx-row">
                <div class="vx-col w-full md:w-1/3 sm:w-1/2 mb-base" v-for="item in filteredKB" :key="item.id" >
                    <vx-card class="text-center cursor-pointer">
                        <a target="_blank" :href='item.url' ><img :src='item.img' :alt="item.title" height="100" width="150" class="mx-auto mb-4"></a>
                        <h4 class="mb-2">{{ item.title.toUpperCase() }}</h4>
                        <small>{{ item.description }}</small>
                    </vx-card>
                </div>
            </div>  
    </div>
</template>

<script>

export default{
    data() {
        return {
            knowledgeBaseSearchQuery: '',
            kb: [
								{ id: 1, title: 'SBS Teknik', description: 'SBS, endüstriyel malzeme ve iş güvenliği malzemelerinde tek adres.', graphic: 'graphic-1.png' ,img: 'http://sbsteknik.com/wp-content/uploads/2019/04/sbslogo.png',url: 'http://sbsteknik.com'},
								{ id: 2, title: 'Avrasya Safety', description: 'Avrasya Safety olarak 20 yıllık iş ve mühendislik bilgimizin ışığında, is sağlığı ve güvenliği alanındaki deneyim ve uzmanlığımızı çeşitli alanlardaki tüm risk sahipleriyle paylaşmayı esas amaç biliyoruz. Türkiye’nin, alanında en iyi eğitimcilerine, uzmanlarına sahip markamızın ilkesi, her zaman gelişmeye önem veren politikasıyla hep en iyi olmaya çalışmaktır.', graphic: 'graphic-1.png' ,img: 'https://avrasyasafety.com/wp-content/uploads/2018/03/logo.png', url:'https://avrasyasafety.com'},
								{ id: 3, title: 'Gürle Suni Deri', description: 'Gürle ,Suni deri sektöründe koagüle, non-woven, dokuma ve örme zeminli PU, PU/PVC ürün gruplarında döşemelik, ayakkabılık, giyimlik, çantalık ve astarlık sektörüne yüzlerce ürün çeşidi ile hizmet vermektedir.', graphic: 'graphic-1.png' ,img: 'https://www.gurlesunideri.com/uploads/design_management/2/thumbnails/normal_logo_1512742074.png',url:'https://www.gurlesunideri.com'},
								{ id: 4, title: 'Aşan Tekstil', description: 'Asanteks, markaya ve üretime yeni bir açılımla bakıyor.Asanteks, markaya ve üretime yeni bir açılımla bakıyor. Ev ve ofis mobilyaları için döşemelik suni deri ve kumaş üreten Aşanteks, 1986 kuruluş tarihinden bugüne kadar geçen yıllarda, alternatif yaratan renk ve desen seçenekleriyle hem Türkiye’de hem de yurt dışında da tercih edilen bir marka haline gelmiştir.', graphic: 'graphic-1.png' ,img: 'https://www.asanteks.com.tr/Content/images/logo.svg',url:'http://www.asanteks.com'},
								{ id: 5, title: 'Boydem', description: 'Boydem Ltd. 1991 yılında kurulmuş olup, elektrik izolasyon malzemeleri, kablo aksesuarları ve özel test cihazları alanında üretici, distribütör ve ithalatçı firma olarak faaliyetlerini sürdürmektedir.', graphic: 'graphic-1.png' ,img: 'http://www.boydem.com/images/firma/1143/logo/663e2b16aa7949a883768802823e18ef_boydem12.jpg',url:'http://www.boydem.com'},
								{ id: 6, title: 'Serra İnşaat', description: 'İnşaat sektörünün en önemli alanrından olan altyapı ve hafriyat işerinde 1989 yılında başlayan tecrübe birikimimiz ile bugün Mustafa Berber ve İhsan Berber ortaklığında yönetilen firma, Türkiye’de yarattığı marka değeri, organizasyon yapısı ve tam hizmet konsepti ile sektörde yön veren önder firmalar arasında yerini almıştır', graphic: 'graphic-1.png' ,img: 'http://www.serrainsaat.com.tr/wp-content/uploads/2018/06/logoyatay-1-1.png',url:'http://www.serrainsaat.com.tr'},
								{ id: 7, title: 'Efor', description: 'Efor Mühendislik, 25 yılı aşan tecrbesiyle plastik endüstrisine hizmet vermektedir. Firmamız her markadan enjeksiyon ve ekstrusyon makinelerine uygun; kovan, vida ve yardımcı ekipmanların tasarımını ve imalatını yapmaktadır.', graphic: 'graphic-1.png' ,img: 'http://eforltd.com/images/logo.png',url:'http://eforltd.com'},
								{ id: 8, title: 'Transel', description: '1960 yılında kurulmuş olan Transel Elektroteknik, o yıldan bugüne ithalatçı firma sıfatıyla elektroteknik ve aydınlatma sanayiinin birçok alanına destek verdi, önemli Avupa şirketlerinin Türkiye’deki dağıtıcılığını başarıyla üstlendi. Şirket 1986 yılında Vimar S.p.A ile yaptığı anlaşma sonucunda VIMAR markasının Türkiye Distribütörlüğünü aldı ve VIMAR markası altında üretilen çeşitli ürün gruplarını Türkiye’ye ithal etmeye başladı.', graphic: 'graphic-1.png' ,img: 'http://transel.com.tr/templates/transel/images/topbar_vimarlogo.png',url:'http://www.transel.com.tr'},
								{ id: 9, title: 'Ulusoyspor', description: '1996 yılından günümüze tüm ticari faaliyetlerimizi titizlikle yürütmeye devam etmekteyiz. Genç beyin ve araştırmacı dinamik yapısı ile, geleceğe hedef belirleyip her geçen günü daha iyi değerlendirerek kurumsal yapıyı oluşturmuş, bu güne ayağı sağlam basan, günün koşullarını iyi analiz eden, hedef tutturabilen, güçlü, her gün gücüne güç katarak 2019 yılında, geniş bayi ağı ve Türkiye genelinde bayileşme sürecine doğru hızla ilerlemeye devam ediyor.', graphic: 'graphic-1.png' ,img: 'https://www.ulusoyspor.com/Uploads/EditorUploads/ulusoysportoptan.png',url:'https://www.ulusoyspor.com'},
								{ id: 10, title: 'YKS Enerji', description: 'YKS enerji, EPDK tarafından verilmiş olan ‘Elektrik Toptan Satış Lisansı’ na sahip, Türkiye genelinde elektrik tedariki sağlayan bir firmadır.2001 yılında yürürlüğe giren 4628 sayılı Elektrik piyasası kanunu çerçevesinde faaliyetlerine devam eden YKS enerji, elektrik enerjisini mevcut elektrik tarifelerinden daha ucuza sağlamakta ve konusunda uzman kadrosuyla elektrik satışında müşterilerine ciddi avantaj sağlamaktadır.', graphic: 'graphic-1.png' ,img: 'http://www.yksenerji.com/wp-content/uploads/2014/08/logo.png',url:'http://www.yksenerji.com'},
								{ id: 11, title: 'Cudo Ayakkabı', description: 'Cudo Ayakkabı', graphic: 'graphic-1.png' ,img: 'https://www.cudoayakkabi.com/Uploads/EditorUploads/can.jpg',url:'http://www.cudoayakkabi.com'},
								{ id: 12, title: 'Akspa Brode', description: 'Sektörü yönlendiren ve başarılı takım arkadaşlarıyla yolculuğuna devam eden Akspa Tekstil, ilerici süreçlerde KOBİ kimliğini kurumsal bir yapıya doğru ilerleterek sektördeki lider firmalardan biridir.Müşteri odaklı ve ihracat koşullarında edindiği tecrübelerle, daha iyi hizmet alanında kendini geliştirmiş ve sektörde hizmet vermeye devam etmektedir.', graphic: 'graphic-1.png' ,img: 'https://akspabrodedantel.com/Assets/img/logos/logo1-1.png',url:'http://akspabrodedantel.com'},
								{ id: 13, title: 'Bizim Otel', description: 'Bir restorana ev sahipliği yapan Bizim Hotel, Fatih`te yer almaktadır. Otel, ücretsiz Wi-Fi ve günün 24 saati açık resepsiyon sunmaktadır.', graphic: 'graphic-1.png' ,img: 'https://irp-cdn.multiscreensite.com/9d0f246b/dms3rep/multi/mobile/Bizim-Otel-Logo.png',url:'http://www.bizimotel.com.tr'},
								{ id: 14, title: 'Çamlıbel Kağıt', description: '1977 yılında Güneş Ticarette başlattığımız toplama ve ayrıştırma işini 1979 yılında Zafer Ticaret adlı şirket kurarak kendimiz yapmaya başladık.Çamlıbel Kağıt Hurda Nakliyat Sanayi ve Ticaret Limited Şirketi 1995 yılından bu yana Sultançiftliğinde faaliyetini devam ettirmektedir. ', graphic: 'graphic-1.png' ,img: 'http://www.camlibelkagit.com/images/logo.png',url:'http://www.camlibelkagit.com'},
								{ id: 15, title: '3S Tur', description: '1990 yılında kurulan 3stur vip minibüs kiralama firmamız caravelle kiralama konusunda sektörün öncü firmalarındandır. Sadece caravelle kiralama konusunda değil vito kiralama , viano kiralama ve diğer vip minibüs kiralama konusunda uzman olan firmamız siz değerli misafirlerimizin yolculuklarını “Vip” Class ‘ mana yükseltiyor.Full özellikli araçlarımızla yolda geçireceğiniz zamanı keyifli ve konforlu hale getiriyoruz', graphic: 'graphic-1.png' ,img: 'https://www.3stur.com.tr/img/3s-logo.png',url:'https://www.3stur.com.tr'},
								{ id: 16, title: 'Aköz', description: '1996 yılında Gurubumuzun inşaat faaliyetlerini bir çatı altında toplamak için kurulmuş bir aile şirketidir. Temel hedefi nüfus artışı ve ekonomik gelişmeye bağlı olarak artan nitelikli konut, iş merkezleri ve alış veriş merkezleri ihtiyaçlarına tüketici tercihlerini ön palanda tutarak çözüm üretmektir.', graphic: 'graphic-1.png' ,img: 'http://www.akoz.com.tr/images/button-1.jpg',url:'http://www.akoz.com.tr'},
								{ id: 17, title: 'Cuma Teknik', description: 'Cama Teknik, teknik hırdavat sektöründe faaliyet gösteren, en kaliteli malzemeler ile, kalıp ve hassas mekanik işlemler için takımlar, kesici uçlar, ve frezeler üreten ve bunların pazarlamasını bizzat kendisi yapan bir firmadır.', graphic: 'graphic-1.png' ,img: 'http://www.camateknik.com/images/camateknik_logo.gif',url:'http://www.camateknik.com'},
								{ id: 18, title: 'Kartoks Plastik', description: '1999 yılında Muzaffer ÇİZGİCİ tarafından şahıs firması olarak kurulan firmamız, 2014 yılında Kartoks Karton Kâğıt ve plastik Sanayi Ticaret Limited Şirketi olarak faaliyetlerini sürdürmektedir. Faaliyet Konumuz; Plastik atıklardan granül ham madde üretimi konusunda hizmet vermek ve geri dönüşüme katkı sağlamaktır.', graphic: 'graphic-1.png' ,img: 'http://www.kartoksplastik.com/images/logo.png',url:'http://www.kartoksplastik.com'},
								{ id: 19, title: 'Yıldız Galvaniz', description: 'YILDIZ GALVANİZ SAC SANAYİ VE TİCARET LTD.ŞTİ. Yenibosna da bulunan 4000 m2 kapalı olmak üzere toplam 6000 m2 lik alanda faaliyet göstermektedir. Değişik sektörlerde orta ve küçük ölçekli sanayici müşterilere ve nihai kullanıcılara hizmet vermekteyiz. Bunlar ; otomotiv yan sanayi , boru profil,inşaat ve yan sanayi,beyaz eşya üreticileri , makine ve yedek parça,ısıtma ve havalandırma,elektrik malzemeleri ve yan sanayi,çelik mobilya imalatçıları,çelik merkezleri ve bayileri,radyatör ve panel üreticileridir.-	Soğuk ve sıcak çekilmiş rulo ve paket saclar -	Soğuk çekilmiş bantlar (dilme) -	HRP rulo ve paket saclar -	Galvanizli saclar', graphic: 'graphic-1.png' ,img: 'http://www.yildizgalvaniz.com/content/yildiz/images/yildiz-logo.png',url:'http://www.yildizgalvaniz.com'},
								{ id: 20, title: 'Hipertech', description: '1997 yılından itibaren, HBO Grup çatısı altında 7 özel HBO Merkezi açarak hiperbarik alanında tecrübe sahibi olan, Dr. Aytunç YUR ve Dr. Ender İNCİ 2005 yılında Hipertech Firmasını kurmuştur. HBO merkezlerinden edinilen bilgi birikimleri sonucunda Hipertech Elektronik ve Makine Endüstri Şirketi açılmıştır. Hipertech aynı zamanda İstanbul Üniversitesi Teknokent’te HBO Grup adıyla varlığını sürdürerek üniversitelerle iş birliği yapmaktadır. Ayrıca, 2 adet TUBİTAK projesini de başarıyla tamamlamıştır. ', graphic: 'graphic-1.png' ,img: 'https://static.wixstatic.com/media/6b5e2a_6dca8522a303410f954cb489b2390bc7~mv2.png/v1/fill/w_363,h_112,al_c,q_85,usm_0.66_1.00_0.01/6b5e2a_6dca8522a303410f954cb489b2390bc7~mv2.webp',url:'http://www.hipertech.com.tr'},
								{ id: 21, title: 'Tulipack', description: 'TULİPACK, hem mevcut kapasitesini artırdı hem de margarin, dondurma, süt ürünleri, dondurulmuş gıda, catering, şekerleme ve çikolata başta olmak üzere geniş bir ürün yelpazesine sahip oldu. Yine 2014 yılında TULİPACK, yurt içindeki geniş müşteri portföyünün yanı sıra yurt dışında ki müşterilere de ulaşmayı başarıp, ihracat faaliyetlerine başladı.', graphic: 'graphic-1.png' ,img: 'http://www.tulipack.com.tr/tulipack/assets/img/tulipack-logo.png',url:'http://www.tulipack.com.tr'},
								{ id: 22, title: 'Birimza', description: 'Bir İmza Sanat Organizasyon, Geleneksel Türk Sanatları ve Modern Sanat Eserleri alanlarında, alanında öncü sanatçılar ile sergi organizasyonları , atölyeler, seminerler ve çeşitli projelerle faaliyetlerini sürdürmektedir.', graphic: 'graphic-1.png' ,img: 'http://www.birimza.com.tr/images/logo.png',url:'http://www.birimza.com.tr'},
								{ id: 23, title: 'Solena Enerji', description: 'Yenilenebilir enerji sektöründe faaliyet göstermek amacıyla 2013 yılında limited şirket olarak kurulan firmamız, "Enerji & Telekomünikasyon" sektöründe, kullanılan yöntem ve kaliteli ürünler ile en yüksek verimde en ucuz şekilde temiz enerji üretmektedir. Şirket politikamız', graphic: '' ,img: 'http://www.solenaenerji.com/images/solena-logo.svg?crc=362430736',url:'http://www.solenaenerji.com'},
								{ id: 24, title: 'Ceiba', description: 'CEIBA TELE-ICU teknolojinin tüm olanaklarını kullanarak ve kendi yazılımını geliştirerek yoğun bakım yönetiminde en ideal koşulları oluşturmakta kararlı olarak yola çıktı. Yoğun bakım ortamı kesintisiz olarak deneyimli ve yetkin uzmanlar tarafından izlenecektir. Bu amaçla, alanlarında kendini kanıtlamış profesyonellerden oluşan kadrosuyla yüksek teknolojik alt yapıyı kurdu. Yüksek kalite standartları kapsamında proses prosedürlerini belirledi.', graphic: '' ,img: 'http://www.ceibateleicu.com/assets/images/template/logo.png',url:'http://www.ceibateleicu.com'},
								{ id: 25, title: 'Eliz', description: 'Eliz Yapışkanlı Bant ve İzolasyon Malzemeleri, kurulduğu 1997 yılından bu yana müşteri odaklı hizmet anlayışı ile çalışmakta ve satışını sunduğu ürünlerle müşterilerinin ihtiyaçları doğrultusunda fiyat/kalite dengeli çözüm üretmektedir.', graphic: '' ,img: 'http://www.eliz.com.tr/img/logo.png',url:'http://www.eliz.com.tr'},
								{ id: 26, title: 'Eto Enerji', description: 'ETO Enerji A.Ş. alçak gerilim, orta gerilim ve zayıf akım elektrik tesisat işleri ve otomasyon konularında mühendislik, proje, taahhüt, teknik servis, bakım ve müşavirlik hizmetleri vermek üzere 2003 yılında kurulmuştur. 25’i aşkın sabit teknik kadrosuyla alanında hizmet vermektedir.', graphic: '' ,img: 'https://static.wixstatic.com/media/b5ee60_67c47bc1974b47cfac3c15584d882b8e~mv2.png/v1/fill/w_402,h_137,al_c,lg_1,q_85/7.webp',url:'http://www.etoenerji.com.tr'},
								


                
            ]
        }
    },
    computed: {
        filteredKB() { 
            return this.kb.filter((item) => item.title.toLowerCase().includes(this.knowledgeBaseSearchQuery.toLowerCase()) || item.description.toLowerCase().includes(this.knowledgeBaseSearchQuery.toLowerCase()));
        }
    },
    methods: {},
    components: {}
}
</script>

<style lang="scss">
.knowledge-base-jumbotron-content {
    background-image: url('../../assets/images/pages/knowledge-base-cover.jpg');
    background-size: cover;
}
</style>



